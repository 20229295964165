:root {
    --base-size: 200px; 
    --anim-time: 3s;
  }
  
  #anim-wrapper {
    width: var(--base-size);
    height: var(--base-size);
    margin: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 2rem;
  }
  #anim-bg {
    border-radius: 50%;
    margin: 100px auto;
    width:0;
    height: 0;
    background: -moz-linear-gradient(top, #f0f9ff 0%, #cbebff 47%, #a1dbff 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #f0f9ff 0%,#cbebff 47%,#a1dbff 100%); /* Chrome10-25,Safari5.1-6 */
  background: #16a0db; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border: calc(var(--base-size) / 7.5) solid #258dc8;
    animation: var(--anim-time) bg-pop ease-in-out infinite;
    overflow: hidden;
    transform: scale(0);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #env-wrapper {
    animation: var(--anim-time) env-fly ease-in-out infinite;
    position: relative;
    margin-bottom: calc(var(--base-size) / 30);
  }
  
  #env {
    color: #fefefe;
    font-size: calc(var(--base-size) / 2.5);
  /*   text-shadow: -15px 15px 0px rgba(0, 172, 230,0.45); */
      text-shadow: -15px 15px 0px #0d76bd;
    animation: mail-stretch var(--anim-time) linear infinite;
  }
  
  @keyframes mail-stretch {
    0% {
      transform: skew(-20deg, 0)
    }
    21% {
      transform: skew(-20deg, 0)
    }
    26% {
      transform: skew(10deg, 0)
    }
    30% {
      transform: skew(0)
    }
    100% {
      transform: skew(0)
    }
  }
  
  #env-wrapper img {
    width: 120px;
    filter: drop-shadow(-20px 15px 0px rgba(0, 172, 230,0.75));
    animation: mail-stretch var(--anim-time) linear infinite;
  }
  
  @keyframes env-fly {
    0% {
      transform: translateX(calc(var(--base-size) * -1.5));
    }
    25% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(10px);
    }
  }
  
  @keyframes bg-pop {
    10% {
      border: 35px solid #0d76bd;
    }
    25% {
      width: calc(var(--base-size) * .8);
      height: calc(var(--base-size) * .8);
      transform: scale(1);
      border: 0px solid #0d76bd;
    }  
  
    100% {
      width: calc(var(--base-size) * .8);
      height: calc(var(--base-size) * .8);
      border: 0px solid #0d76bd;
      transform: scale(1)
    }
  }
  
  .speedline {
    position: absolute;
    height: 3px;
    background: rgb(0,43,84);
    left: 0;
    transform: translateX(-100%);
    opacity: 0.35;
    border-radius: 20px;
  }
  
  .line1 {
    top: 25%;
    width: calc(var(--base-size) / 3.3);;
    animation: speedline1 var(--anim-time) linear infinite;
  }
  
  .line2 {
    top: 50%;
    width: calc(var(--base-size) / 4);;
    animation: speedline2 var(--anim-time) linear infinite;
  }
  
  .line3 {
    top: 75%;
    width: calc(var(--base-size) / 5);
    animation: speedline3 var(--anim-time) linear infinite;
  }
  
  @keyframes speedline1 {
    0% { width: 90px }
    20% { width: 90px }
    28% {width: 0}
    100% {width: 0}
  }
  
  @keyframes speedline2 {
    0% { width: 75px }
    20% { width: 75px }
    28% {width: 0}
    100% {width: 0}
  }
  
  @keyframes speedline3 {
    0% { width: 60px }
    20% { width: 60px }
    28% {width: 0}
    100% {width: 0}
  }
  
  #check-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    right: calc(var(--base-size) / 4.6);
    bottom: calc(var(--base-size) / 3);
    position: absolute;
    border-radius: 50%;
    width: 34px;
    height: 34px;
  /*   background: #90cc91; */
    background: #a0cf67;
    overflow: visible;
    border: 3px solid white;  
    animation: var(--anim-time) check-pop ease-in-out infinite;
  }
  
  #check-container.img-version {
    right: 80px;
    bottom: 105px;
  }
  
  #check-container2 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    right: 65px;
    bottom: 100px;
    position: absolute;
    border-radius: 50%;
    width: 27px;
    height: 27px;
    background: #90cc91;
  /*   overflow: hidden; */
    border: 3px solid white;  
  }
  
  @keyframes check-pop {
    0% {
      transform: scale(0);
    }
    25% {
      transform: scale(0);
    }
    35% {
      transform: scale(1);
    }
  
  }
  
  .check-stroke1 {
    width: 9px;
    height: 4px;
    position: absolute;
    background: white;
    border-radius: 5px;
    top: 14px;
    left: 5px;
    transform: rotate(45deg);
    animation: check-stroke1 var(--anim-time) linear infinite;
  }
  
  .check-stroke2 {
    width: 15px;
    height: 4px;
    position: absolute;
    background: white;
    border-radius: 5px;
    top: 13px;
    left: 9px;
    transform: rotate(-45deg);
    animation: check-stroke2 var(--anim-time) linear infinite;
  }
  
  @keyframes check-stroke1 {
    0% { width: 0}
    34% { width: 0}
    37% { width: 9px}
    100% { width: 9px}
  }
  
  @keyframes check-stroke2 {
    0% { width: 0}
    38% { width: 0}
    41% { width: 15px}
    100% { width: 15px}
  }
  