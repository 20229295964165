@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

#loading-text {
  text-align: center;
  margin-top: 2rem;
}

#loading-text span {
  animation-name: dot;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

#loading-text span:nth-child(1) {
  animation-delay: 0.2s;
}

#loading-text span:nth-child(2) {
  animation-delay: 0.4s;
}

#loading-text span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dot {
  0%,
  20%,
  50%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

* {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
:root {
  --hue: 223;
  --bg: hsl(var(--hue), 90%, 95%);
  --fg: hsl(var(--hue), 90%, 5%);
  --trans-dur: 0.3s;
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
}

.ip {
  width: 8em;
  height: 4em;
  margin: 0 auto;
  margin-top: 4rem;
}
.ip__track {
  stroke: hsl(var(--hue), 90%, 90%);
  transition: stroke var(--trans-dur);
}
.ip__worm1,
.ip__worm2 {
  animation: worm1 2s linear infinite;
}
.ip__worm2 {
  animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 5%);
    --fg: hsl(var(--hue), 90%, 95%);
  }
  .ip__track {
    stroke: hsl(var(--hue), 90%, 15%);
  }
}

/* Animation */
@keyframes worm1 {
  from {
    stroke-dashoffset: 0;
  }
  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }
  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes worm2 {
  from {
    stroke-dashoffset: 358;
  }
  50% {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -358;
  }
}

.home-bg {
  min-height: fit-content;
  width: 100%;
  background-image: url(images/home-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.chat-bg {
  min-height: fit-content;
  width: 100%;
  background-image: url(images/mobile-bg-01.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.home-bg2 {
  min-height: 5dvh;
  width: 100%;
  background-image: url(images/home-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}





#iframe-container {
  width: 100%;
  height: 90dvh;
  display: hidden;
  margin-top: 3rem;
}

#iframe-container iframe {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Mont";
  src: url("./images/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "MontBlack";
  src: url("./images/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Output";
  src: url("./images/SpaceMono-Regular.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Pacifico";
  src: url("./images/Pacifico-Regular.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@media (max-width: 670px) {
  .hideMobile {
    display: none;
  }
  .home-bg {
    min-height: fit-content;
    width: 100%;
    background-image: url(images/mobile-bg-01.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }

  .home-bg2 {
    min-height: 5dvh;
    width: 100%;
    background-image: url(images/mobile-bg-01.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
  }
}

.blocksatz {
  text-align: justify;
  text-justify: inter-word;
}

@media (min-width: 670px) {
.onlyMobile {
  display: none;
}
}